<div class="background">
  <div class="components">
    <div class="title">
      <p style="margin: 0">Bienvenue sur MyStore</p>
    </div>
    <div class="my-carousel">
      <!-- TODO: Add carousel component in Angular 15-->
      <!--<mat-carousel
        [autoplay]="false"
        [hideArrows]="false"
        [loop]="false"
        [hideIndicators]="false"
        maxWidth="{{ carouselWidth }}"
        color="mystore-theme"
        [maintainAspectRatio]="false"
        slideHeight="{{ carouselHeight }}"
        style="background-color: inherit"
      >
        <mat-carousel-slide *ngFor="let image of content.images" [image]="image.path" [hideOverlay]="true"></mat-carousel-slide>
      </mat-carousel>-->
    </div>
    <button class="button" (click)="goRestaurantList()">C'EST PARTI !</button>
  </div>
</div>
