import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorRoleComponent } from './component/common/error-role/error-role.component';
import { ErrorComponent } from './component/common/error/error.component';
import { HeaderComponent } from './component/common/header/header.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { FaqComponent } from './component/faq/faq.component';
import { OauthComponent } from './component/oauth/oauth.component';
import { OnboardingComponent } from './component/onboarding/onboarding.component';
import { OrderParkingComponent } from './component/restaurant-order-parking/order-parking.component';
import { RestaurantDeliveriesComponent } from './component/restaurant-deliveries/restaurant-deliveries.component';
import { RestaurantFacilitiesComponent } from './component/restaurant-facilities/restaurant-facilities.component';
import { RestaurantListComponent } from './component/restaurant-list/restaurant-list.component';
import { RestaurantOrdersComponent } from './component/restaurant-orders/restaurant-orders.component';
import { RestaurantSettingsComponent } from './component/restaurant-settings/restaurant-settings.component';
import { Role } from './models/role.enum';
import { AuthGuardService } from './service/connect/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/restaurant-list', pathMatch: 'full' },
  { path: 'oauth', component: OauthComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'error-role', component: ErrorRoleComponent },
  {
    path: 'restaurant/dashboard',
    component: DashboardComponent, // Dashboard
    canActivate: [AuthGuardService],
    data: { roles: [Role.MANAGER, Role.ORDER_VIEWER, Role.ORDER_UPDATER, Role.RESTAURANT_VIEWER] },
  },
  {
    path: 'restaurant/orders',
    component: RestaurantOrdersComponent, // restaurant orders management
    canActivate: [AuthGuardService],
    data: { roles: [Role.MANAGER, Role.ORDER_VIEWER, Role.ORDER_UPDATER] },
  },
  {
    path: 'restaurant/settings',
    component: RestaurantSettingsComponent, // restaurant settings
    canActivate: [AuthGuardService],
    data: { roles: [Role.MANAGER, Role.RESTAURANT_VIEWER] },
  },
  {
    path: 'restaurant/facilities',
    component: RestaurantFacilitiesComponent, // restaurant facilities management
    canActivate: [AuthGuardService],
    data: { roles: [Role.MANAGER, Role.RESTAURANT_VIEWER] },
  },
  {
    path: 'restaurant/deliveries',
    component: RestaurantDeliveriesComponent, // restaurant deliveries
    canActivate: [AuthGuardService],
    data: { roles: [Role.MANAGER, Role.RESTAURANT_VIEWER] },
  },
  {
    path: 'restaurant-list',
    component: RestaurantListComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.MANAGER, Role.ORDER_VIEWER, Role.ORDER_UPDATER, Role.RESTAURANT_VIEWER] },
  },
  { path: 'faq', component: FaqComponent },
  // {
  //   path: 'onboarding',
  //   component: OnboardingComponent,
  //   canActivate: [AuthGuardService],
  //   data: { roles: [Role.MANAGER, Role.ORDER_VIEWER, Role.ORDER_UPDATER] },
  // },
  {
    path: 'restaurant/orders-parking',
    component: OrderParkingComponent,
    canActivate: [AuthGuardService],
    data: { roles: [Role.MANAGER, Role.ORDER_VIEWER, Role.ORDER_UPDATER] },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
