import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Role } from '../../models/role.enum';
import { Token } from '../../models/token.model';
import { HttpCancelService } from '../http-cancel/http-cancel.service';
import { PersistanceService } from '../persistance/persistance.service';
import { UserService } from '../user/user.service';
import { ErrorService } from '../error/error.service';
import { ConsoleLoggerService } from '../logger/console-logger.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectService {
  private readonly isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private readonly isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  isLoggedIn = false;
  private readonly FORM_URL_ENCODED = 'application/x-www-form-urlencoded';

  constructor(
    private readonly router: Router,
    private readonly httpClient: HttpClient,
    private readonly userService: UserService,
    private readonly cookieService: CookieService,
    private readonly httpCancelService: HttpCancelService,
    private readonly persistanceService: PersistanceService,
    private readonly errorService: ErrorService,
    private readonly CONSOLE_LOGGER: ConsoleLoggerService
  ) {}

  /**
   * Run the initial login sequence : redirect to connect service for user to log in.
   */
  runInitialLoginSequence(): void {
    if (this.hasValidToken() || !this.isNull(this.persistanceService.get('redirectPage'))) {
      this.isLoggedIn = true;
      this.userService.getUserInfo().subscribe(() => this.isDoneLoadingSubject$.next(true));
    } else if (window.location.href.indexOf('code') === -1) {
      window.location.href =
        environment.settings.connect.loginUrl +
        '?response_type=code&client_id=' +
        environment.settings.connect.clientId +
        '&redirect_uri=' +
        environment.settings.connect.redirectUrl +
        '&scope=openid profile offline_access&state=' +
        this.generateRandomString(8);
    }
  }

  private isNull(label: string): boolean {
    return label == null || label === '' || label === undefined;
  }

  /**
   * Generate a random string
   * @param length Length of the generated string
   * @returns the random string
   */
  private generateRandomString(length: number): string {
    return Math.random().toString(36).substr(2, length);
  }

  /**
   * Call OAuth service to get access_token from an authorization code.
   * Then redirect to first page (restaurant-list)
   * @param code Authorization code
   */
  retrieveToken(code): void {
    const params = new HttpParams().set('grant_type', 'authorization_code')
    .set('client_id', environment.settings.connect.clientId)
    .set('client_secret', environment.settings.connect.clientSecret)
    .set('redirect_uri', environment.settings.connect.redirectUrl)
    .set('code', code);
    const httpHeaders = new HttpHeaders({ 'Content-Type': this.FORM_URL_ENCODED });

    this.httpClient
      .post<any>(environment.settings.connect.tokenEndpoint, params.toString(), { headers: httpHeaders })
      .subscribe((data) => {
        this.saveToken(data);
        this.userService.getUserInfo().subscribe(() => {
          this.isDoneLoadingSubject$.next(true);
          if (this.shouldSeeOnBoarding()) {
            this.router.navigate(['/onboarding']);
          } else {
            this.router.navigate(['/restaurant-list']);
          }
        });
      });
  }

  /**
   * Checks if the user should see the onboarding screen
   * ie: not seen and role in MANAGER/ORDER_UPDATER/ORDER_VIEWER
   */
  private shouldSeeOnBoarding(): boolean {
    let userInfos;
    this.userService.getUserInfo().subscribe((data) => (userInfos = data));
    let hasOnBoardingRole = false;
    if (userInfos) {
      hasOnBoardingRole =
        userInfos.roles.includes(Role.MANAGER) ||
        userInfos.roles.includes(Role.ORDER_UPDATER) ||
        userInfos.roles.includes(Role.ORDER_VIEWER);
    }
    return false;
    // return hasOnBoardingRole && this.cookieService.get('onboarding-seen') !== environment.onboardingVersion;
  }

  /**
   * Call OAuth service to try to refresh expired access_token.
   * If refresh_token_validity is also expired, run the login sequence.
   */
  refreshToken(runLoginSequence: boolean = true): Observable<any> {
    const params = new HttpParams()
    .set('grant_type', 'refresh_token')
    .set('client_id', environment.settings.connect.clientId)
    .set('client_secret', environment.settings.connect.clientSecret)
    .set('refresh_token', sessionStorage.getItem('refresh_token'));
    const httpHeaders = new HttpHeaders({ 'Content-Type': this.FORM_URL_ENCODED });

    return this.httpClient
      .post<any>(environment.settings.connect.tokenEndpoint, params.toString(), { headers: httpHeaders })
      .pipe(
        map((data) => {
          this.saveToken(data);
          return data.access_token;
        }),
        catchError((err) => {
          this.CONSOLE_LOGGER.error('Error during refreshToken(), cancel pending requests and redirect to login', err);
          this.errorService.manageError(err);
          this.httpCancelService.cancelPendingRequests();
          // DOIT ON FAIRE UN LOGOUT AVANT ??
          if (err.url.toLocaleLowerCase() !== environment.settings.connect.manageTokenEndpoint) {
            window.location.href =
              environment.settings.connect.loginUrl +
              '?response_type=code&client_id=' +
              environment.settings.connect.clientId +
              '&redirect_uri=' +
              environment.settings.connect.redirectUrl +
              '&scope=openid profile offline_access&state=' +
              this.generateRandomString(8);
          } else if (runLoginSequence) {
            this.runInitialLoginSequence();
          }
          return of([]);
        })
      );
  }

  /**
   * Save token informations in sessionStorage
   * @param token Token data to save
   */
  saveToken(token: Token): void {
    sessionStorage.setItem('access_token', token.access_token);
    sessionStorage.setItem('expires_in', String(new Date().getTime() + 1000 * token.expires_in));
    sessionStorage.setItem('refresh_token', token.refresh_token);
    sessionStorage.setItem('token_type', token.token_type);
  }

  /**
   * Revoke and delete access token.
   */
  revokeToken(): void {
    const params = new HttpParams()
    .set('token', this.getToken())
    .set('client_id', environment.settings.connect.clientId)
    .set('client_secret', environment.settings.connect.clientSecret);
    const httpHeaders = new HttpHeaders({ 'Content-Type': this.FORM_URL_ENCODED });

    this.httpClient
      .post<any>(environment.settings.connect.manageTokenEndpoint, params.toString(), { headers: httpHeaders })
      .subscribe();
  }

  /**
   * Process the logout actions to disconnect user and clear sessionStorage.
   */
  logOut(): void {
    this.revokeToken();
    sessionStorage.clear();
    window.location.href =
      environment.settings.connect.logoutUrl + '?client_id=' + environment.settings.connect.clientId + '&redirect_reference=root';
  }

  /**
   * Gets the access_token from the sessionStorage.
   */
  getToken(): string {
    return sessionStorage.getItem('access_token');
  }

  /**
   * Checks if current access_token is still valid and not expired.
   */
  hasValidToken(): boolean {
    return (
      sessionStorage.getItem('access_token') &&
      sessionStorage.getItem('expires_in') &&
      new Date(Number(sessionStorage.getItem('expires_in'))) > new Date()
    );
  }
}
